.container {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 8px;
  border-radius: 5px;
  /* height: 90%; */
  /* margin-top: 20px; */
  width: 90%;
  min-height: 90%;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
}
