:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: #f5f5f5;
}

.form-container {
  padding: 15px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .form-container {
    padding: 0px;
    width: 100%;
  }
}

::selection {
  background-color: #9133ff;
  color: white;
}
